<template>
	<div class="xianzhuang">
		<div class="sizhiCon">
			<div class="topbtn" v-if="ispdf">
				<time-source :data="{ time: imglist[0].created_at, source: imglist[0].source }"></time-source>
				<el-button size="mini" class="downloadbtn" type="primary" @click="downloadImg(imglist[0].id)" icon="el-icon-download">
					下载</el-button>
			</div>
			<thumbs-swiper v-if="!ispdf" :imglist="imglist"></thumbs-swiper>
			<pdf v-if="ispdf" :pdfUrl="imglist[0].url"></pdf>
		</div>
	</div>
</template>

<script>

import TimeSource from "@/components/cityreserch/TimeSource.vue";
import "swiper/css/swiper.css";
import ThumbsSwiper from '@/components/swiper/ThumbsSwiper'
import pdf from '@/components/common/pdf'
import { downloadFileByCate } from '@/api/data'


import { downloadZip } from '@/api/cityresearch'
export default {
	name: 'FrontendPcBigdataXianzhuang',
	components: {
		ThumbsSwiper,
		pdf,
		TimeSource
	},

	data() {
		return {
			// 1出让须知 2规划条件 3720云 4监管协议 5  6 建设方案 7 宗地图 8 红线图 9区位详归 10地块现状 11宗地四至
			
			ispdf: false,
			curruteCate: 2,
			imglist: [],
			id: 0,
			swiperOption: {
				spaceBetween: 30,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
			},

		};
	},

	mounted() {
		if (this.$route.params.id) {
			this.id = this.$route.params.id || 24887
		}
		this.getImgList()
	},


	methods: {
		downloadImg(id) {
			downloadZip({ file_ids: id }).then(res => {
				const url = res.data
				window.location.href = url
			})
		},
		//设置主建
		setPrimary(item) {
			this.menulist.map(i => {
				if (i.name === item.name) {
					i.primary = 'primary'
					this.curruteCate = i.cate
					this.getImgList()
				} else {
					i.primary = ''
				}
			})

		},


		getImgList() {
			downloadFileByCate({ pid: this.id, category_id: this.curruteCate }).then(res => {
				//文件中只有一个文件，并且后缀是pdf 
				this.ispdf = false
				if (res.data.length === 1) {
					if (res.data[0].suffix === 'pdf') {
						this.ispdf = true
					}
				}
				this.imglist = res.data

			})
		},

	},
};
</script>

<style lang="scss" scoped>
.sizhiCon {
	margin-top: 30px;
}

.swiper {
	height: 300px;
	width: 100%;
	border: 1px transparent solid;

	.swiper-slide {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-weight: bold;
		font-size: 14px;
		background-color: rgb(98, 168, 200);
	}
}

.xianzhuang {
	padding-top: 10px;
}

.topbtn {
	display: flex;
	width: 84%;
	margin-left: 7%;
	justify-content: flex-end;
	align-items: center;
}

.downloadbtn {
	margin-left: 30px;
}
</style>